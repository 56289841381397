<template>
  <router-view />
</template>
<script>
import { defineComponent } from 'vue'
import bootMixins from 'mixins/boot'

export default defineComponent({
  name: 'App',
  mixins: [bootMixins],
  async preFetch ({ store }) {
    await store.dispatch('common/initApp')
  },
  created () {
    this.$store.dispatch('common/initApp')
    this.$q.iconMapFn = (iconName) => {
      if (iconName.startsWith('uil:') === true) return { cls: `uil uil-${iconName.substring(4)}` }
    }
  },
})
</script>
