import { get } from 'lodash'

export function categoriesById (state) {
  return get(state, 'categoriesById', {})
}

export function stripeActive () {
  return !!process.env.STRIPE_PUBLISHABLE_KEY
}

export function defaultOfferCategoryId (state, getters, rootState, rootGetters) {
  return get(rootState, 'common.config.stelace.instant.searchOptions.modes.offer.defaultCategoryId')
}

export function offerCategoriesIds (state) {
  return get(state, 'config.stelace.instant.searchOptions.modes.offer.categoryIds', [])
}

export function choId (state) {
  return get(state, 'config.custom.users.cho', null)
}

export function administrativeId (state) {
  return get(state, 'config.custom.users.administrative', null)
}

export function companyTypeId (state) {
  return get(state, 'config.stelace.instant.searchOptions.modes.company.assetTypesIds[0]')
}

export function offerTypeId (state) {
  return get(state, 'config.stelace.instant.searchOptions.modes.offer.assetTypesIds[0]')
}

export function resumeTypeId (state, getters, rootState, rootGetters) {
  return get(rootState, 'common.config.stelace.instant.searchOptions.modes.resume.assetTypesId')
}

export function talkAssetsTypeId (state) {
  return get(state, 'config.custom.assetTypeIds.talkAssets')
}

export function profileTypeId (state) {
  return get(state, 'config.custom.assetTypeIds.profile')
}

export function getConfig (state) {
  return get(state, 'config', {})
}
