import stelace from 'hc-core/composables/stelace'

export async function getStripeCustomerData (context) {
  return await stelace.forward.get('/integrations/stripe/customer', { stelaceOrganizationId: null })
}

export async function getStripeSubscriptions (context, { orgOwnerId = null } = {}) {
  return await stelace.forward.get(`/integrations/stripe/subscriptions${orgOwnerId ? `?orgOwnerId=${orgOwnerId}` : ''}`, { stelaceOrganizationId: null })
}

export async function getStripeInvoices (context, { orgOwnerId = null } = {}) {
  return await stelace.forward.get(`/integrations/stripe/invoices${orgOwnerId ? `?orgOwnerId=${orgOwnerId}` : ''}`, { stelaceOrganizationId: null })
}

export async function getStripePortalSession (context) {
  return await stelace.forward.get('/integrations/stripe/portal/session', { stelaceOrganizationId: null })
}

export async function createStripeCustomer (context, { customer }) {
  return await stelace.forward.post('/integrations/stripe/customer', { customer })
}

export async function getStripeCheckoutSession (context, { lineItems, mode, paymentMethod, customerId, metadata }) {
  return await stelace.forward.post('/integrations/stripe/checkout/session', { lineItems, mode, metadata, paymentMethod, customerId })
}

// New shop
export async function orderCompute (context, { items }) {
  return await stelace.forward.post('/stripe/order/compute', { items })
}

export async function orderProcess (context, { stripeCustomer, items, paymentMethod }) {
  return await stelace.forward.post('/stripe/order/process', { stripeCustomer, items, paymentMethod })
}

export async function orderGet (context, { orderId }) {
  return await stelace.orders.read(orderId)
}
