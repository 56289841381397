// import { useQuasar } from 'quasar'
import { compact, orderBy, uniqBy } from 'lodash'

export function element (state) {
  return state.element
}
export function applications (state) {
  return state.applications
}
export function applicants (state) {
  return state.applicants
}
export function profileAssets (state) {
  return state.profileAssets
}
export function stages (state) {
  return state.stages
}
export function embed (state) {
  return state.embed
}
export function orderByField (state) {
  return state.orderByField
}
export function orderByOrder (state) {
  return state.orderByOrder
}
export function stageModel (state) {
  return state.stageModel
}
export function activeModel (state) {
  return state.activeModel
}

export function kanban (state) {
  const items = uniqBy(
    orderBy(
      state.applications.map((application) => {
        const applicant = state.applicants.find(applicant => applicant.id === application.takerId) ?? null
        let profile = null
        let applicantApplications = null
        if (applicant) {
          profile = state.profileAssets.find(profile => profile.ownerId === applicant.id) ?? null
          applicantApplications = state.applications.filter(app => app.takerId === applicant.id)
        }
        // Prevent JSON sircular dependency in ApplicantCard memoize call by doing this and not setting in applicant directly
        return {
          ...application,
          applicant: applicant ? {
            ...applicant,
            profileAsset: profile ?? undefined,
            applications: applicantApplications ?? undefined,
          } : undefined
        }
      }),
      [state.orderByField ?? 'createdDate'],
      [state.orderByOrder ?? 'desc']
    ),
    'id'
  )

  const stages = compact(state.stages.filter((s) => {
    const stageItems = items.filter(i => s.cond(i))
    if (stageItems.length < 1 && s.removeIfNoItems) return undefined
    else {
      s.items = stageItems
      return s
    }
  }))

  const inactiveStage = stages.find(s => s.inactiveStage)
  const activeStages = stages.filter(s => !s.inactiveStage)
  // const isDesktop = useQuasar().screen.gt.sm
  const isDesktop = true
  const displayedStages = compact(isDesktop ? activeStages : [activeStages.find(s => s.value === state.stageModel.value)])

  return {
    stages,
    inactiveStage,
    displayedStages
  }
}
