import { assign, get, keyBy, merge, omit } from 'lodash'
// import { assign, get, keyBy, merge, omit, set } from 'lodash'

export function setCurrentNaturalUser (state, { user }) {
  state.naturalUser = user
}

export function setCurrentUser (state, { user }) {
  state.user = user
}

export function setOrganizations (state, { organizations }) {
  state.organizations = organizations
  state.organizationsById = keyBy(organizations, 'id')
}

export function setUserCompany (state, { company }) {
  state.userCompany = company
}

export function setUserOrganizations (state, { userOrganizations }) {
  // Allow to overwrite only some field
  state.userOrganizations = assign(state.userOrganizations, omit(userOrganizations, ['stats']))

  // Since stats are sometimes partial, merge is more relevant
  state.userOrganizations.stats = merge(
    get(state, 'userOrganizations.stats', {}),
    get(userOrganizations, 'stats', {})
  )
}

export function setCheckoutData (state, { checkout }) {
  state.checkout = checkout || null
}

export function setHcPerms (state, { hcPerms }) {
  state.hcPerms = hcPerms || []
}
