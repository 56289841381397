// Mixin specific for this project
// For always loaded mixins, refer to mixins/global

export default {
  computed: {
    // isClient () { return this.$store.getters['user/isClient'] },
    // isPhenix () { return this.$store.getters['user/isPhenix'] },
    // isDragon () { return this.$store.getters['user/isDragon'] },
    currentUser () { return this.$store.getters['auth/currentUser'] },
    userCompany () { return this.$store.getters['auth/userCompany'] },
    // isApplicant () { return this.$store.getters['user/isApplicant'] },
    // isSubscribed () { return this.$store.getters['user/isSubscribed'] },
    // isOrganization () { return this.$store.getters['user/isOrganization'] },
    // isOrgRootOwner () { return this.$store.getters['user/isOrgRootOwner'] },
    // isEditorOrAbove () { return this.$store.getters['user/isEditorOrAbove'] },
    userOrganizations () { return this.$store.getters['auth/userOrganizations'] },
    currentNaturalUser () { return this.$store.getters['auth/currentNaturalUser'] },
    // isAdministratorInOrg () { return this.$store.getters['user/isAdministratorInOrg'] },
  },
}
