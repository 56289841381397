export function setInbox (state, { inbox }) {
  state.inbox = inbox
}

// Based on the new endpoint
export function setUserInbox (state, inbox) {
  state.userInbox = inbox
}

export function setTalkAssets (state, { talkAssets }) {
  state.talkAssets = talkAssets
}
