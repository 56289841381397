import state from 'hc-core/stores/kanban/state'
import * as getters from 'hc-core/stores/kanban/getters'
import * as mutations from 'hc-core/stores/kanban/mutations'
import * as actions from 'hc-core/stores/kanban/actions'

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
