export default function () {
  return {
    applicantDisplayerStore: {
      items: [], // Array of applicants
      // index: 0, // Current viewed applicant's index
      loading: false, // Whole list is loading
      // loadingIndex: [], // List all indexes that are loading
      loadingIds: [], // List all ids that are loading

      tab: 'files', // Open tab of dialog
      showDialog: false, // Display dialog or not
      applicantId: null, // Id of applicant to display - discard id at term.

      canGoPrev: false, // Dialog carousel
      canGoNext: false, // Dialog carousel
    }
  }
}
