import { get, intersection } from 'lodash'

export function currentUser (state) {
  return state.user
}

export function currentNaturalUser (state) {
  return state.naturalUser || {}
}

export function userOrganizations (state) {
  return state.userOrganizations
}

export function userCompany (state) {
  return state.userCompany || {}
}

export function roles (state) {
  return state.roles
}

// Extended remap of userOrganizations getter
export function uElements (state) {
  return (key, checkLoading = undefined) => {
    const data = state.userOrganizations
    // List of fields available in stelace/plugins/adv/hc_user/usersElements
    const def = {
      stats: [],
      organizations: [],
      implantations: [],
      companies: [],
      offers: [],
      templates: [],
      questionnaires: [],
      ratings: [],
      brand: null,
      messages: [],
      applications: [],
      applicants: [],
      users: [],
      applicantList: [],
      profileAsset: null,
      // Subscription special part
      // Todo : if possible move all in perks property and only refers to it
      subscription: 'client',
      slots: 0,
      offerCredits: 0,
      addressCredits: 1,
      cvthequeCredits: 0,
      perks: {
        subscription: 'client',
        slots: 0,
        offerCredits: 0,
        addressCredits: 1,
        cvthequeCredits: 0,
        cvthequePackCredits: 0,
      },
      // lastLogin: null, // Not used in app for now
      loading: [],
    }
    // Specifically checking is specified key is loading
    if (checkLoading !== undefined) {
      // If at least one is loading, return true
      if (Array.isArray(key)) return intersection(get(data, 'loading', []), key).length > 0
      else return get(data, 'loading', []).includes(key) ?? false
    } else if (get(data, 'loading', []).includes(key)) return get(def, key, null) // Null value will be used as loading indicator / return null
    else {
      if (['slots', 'offerCredits', 'addressCredits', 'cvthequeCredits', 'cvthequePackCredits'].includes(key)) {
        return get(data, key, get(data, `rootUser.platformData._private.${key}`, get(def, key, null)))
      } else return get(data, key, get(def, key, null))
    }
  }
}

export function hcPerms (state) {
  return get(state, 'hcPerms', [])
}
