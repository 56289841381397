export default function () {
  return {
    user: null,
    checkout: null,
    userCompany: null,
    naturalUser: null,
    organizations: null,
    currentNaturalUser: null,
    userOrganizations: {
      // brand: null,
      // users: [],
      // offers: [],
      // ratings: [],
      // messages: [],
      // companies: [],
      // templates: [],
      // applicants: [],
      // applications: [],
      // implantations: [],
      // organizations: [],
      loading: [], // array listing above keys if they are being reloaded
    },
    hcPerms: [],
    roles: [
      {
        value: 'null',
        icon: 'uil:times-circle',
        color: 'grey-4',
        label: 'Aucun',
        description: 'Sans rôle, un utilisateur n\'appartient pas à une implantation.',
      },
      {
        value: 'member',
        icon: 'uil:user',
        color: 'blue-3',
        label: 'Membre',
        description: 'Un membre peut uniquement consulter les données d\'une implantation mais ne peut en aucun cas y effectuer des modifications.',
      },
      {
        value: 'editor',
        icon: 'uil:receipt-alt',
        color: 'blue-4',
        label: 'Éditeur',
        description: 'Un éditeur peut modifier la plupart des données liées à une implantation, à l\'exception des utilisateurs.',
      },
      {
        value: 'administrator',
        icon: 'uil:cog',
        color: 'blue-5',
        label: 'Administrateur',
        description: 'Un administrateur peut modifier toutes les données liées à une implantation, et peut également gérer les utilisateurs.',
      },
      {
        value: 'owner',
        icon: 'uil:house-user',
        color: 'purple-4',
        label: 'Propriétaire',
        description: 'Un propriétaire possède une implantation.',
      },
      {
        value: 'client',
        icon: 'uil:user-circle',
        color: 'purple-5',
        label: 'Client',
        description: 'Un client est un utilisateur d\'HappyCab.',
      },
      {
        value: 'phenix',
        icon: 'uil:bolt-alt',
        color: 'gold-4',
        label: 'Phénix',
        description: 'Un phénix est un utilisateur souscrivant à un abonnement Phénix.',
      },
      {
        value: 'dragon',
        icon: 'uil:fire',
        color: 'negative',
        label: 'Dragon',
        description: 'Un dragon est un utilisateur souscrivant à un abonnement Dragon.',
      },
      {
        value: 'starter',
        icon: 'uil:shield-plus',
        color: 'primary',
        label: 'Starter',
        description: 'Un starter est un utilisateur souscrivant à un abonnement Starter.',
      },
      {
        value: 'essential',
        icon: 'uil:bolt-alt',
        color: 'gold-4',
        label: 'Essential',
        description: 'Un essential est un utilisateur souscrivant à un abonnement Essential.',
      },
      {
        value: 'premium',
        icon: 'uil:fire',
        color: 'blue-4',
        label: 'Premium',
        description: 'Un premium est un utilisateur souscrivant à un abonnement Premium.',
      },
    ]
  }
}
