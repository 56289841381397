import stelace from 'hc-core/composables/stelace'
import { remapToPaginated } from 'hc-core/composables/misc'

// ##### Generic calls #####

export async function create (context, { attrs }) {
  return await stelace.transactions.create(attrs)
}

export async function read (context, { id }) {
  return await stelace.transactions.read(id)
}

export async function list (context, params) {
  return remapToPaginated(await stelace.transactions.list(params))
}

export async function listAdvanced (context, params) {
  return await stelace.forward.get('transactions/advanced', params)
}

export async function update (context, { id, attrs }) {
  return await stelace.transactions.update(id, attrs)
}

export async function remove (context, { id }) {
  return await stelace.transactions.remove(id)
}
