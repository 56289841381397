import { set } from 'lodash'
import { mergeEntries } from 'hc-core/composables/content'

export async function seti18nContent (state, { content }) {
  state.i18nContent = mergeEntries({ localEntries: content })
}

export function setSubscriptionsList (state, { subscriptions, features }) {
  state.subscriptions = subscriptions
  state.features = features
}

export function setLocale (state, { locale }) {
  state.locale = locale
}

// WIP : flex way
export function setEntries (state, { key, arr = [] }) {
  set(state, key, arr)
}
