export default function () {
  return {
    talkAssets: [],
    inbox: {
      messages: [],
      usersById: {},
      totalUnread: 0,
      conversationsById: {},
      timeSortedConversationsIds: [],
    },
  }
}
