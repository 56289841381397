import stelace from 'hc-core/composables/stelace'

// ##### Generic calls #####

export async function create (context, { rating }) {
  return await stelace.ratings.create(rating)
}

export async function read (context, { ratingId }) {
  return await stelace.ratings.read(ratingId)
}

export async function update (context, { ratingId, data }) {
  return await stelace.ratings.update(ratingId, data)
}

export async function remove (context, { ratingId }) {
  return await stelace.ratings.remove(ratingId)
}
