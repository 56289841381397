import stelace from 'hc-core/composables/stelace'

// > UserInbox
// Advanced method to grab a paginated and feeded inbox of an user
export async function fetchInbox ({ commit }, params) {
  const obj = Object.assign(
    { id: null, page: 1, nbResultsPerPage: 20, query: null },
    { ...params }
  )
  if (!obj.id) return null
  const inboxRequest = await stelace.forward.get('/users/inbox', obj)
  if (inboxRequest.nbResults) commit('inbox/setUserInbox', inboxRequest, { root: true })

  return inboxRequest
}

// Allow archiving messages but also whole conversations
export async function archiveConversation (context, { messageIds = [], convIds = [] }) {
  if (!messageIds.length && !convIds.length) throw new Error()
  return await stelace.forward.patch('/messages/archive', { messageIds, convIds })
}

export async function sendMessage ({ dispatch }, { topicId, content, attachments = [], senderId, receiverId, conversationId = undefined, metadata = undefined }) {
  if (!content.length) return
  await stelace.messages.create({
    topicId,
    content,
    attachments,
    senderId,
    receiverId,
    conversationId,
    metadata,
  })
}

export async function deleteMessage ({ dispatch, rootState }, { message = undefined }) {
  const currentUserId = rootState.auth.user.id
  if (message.senderId && message.senderId.startsWith('org_')) dispatch('auth/selectOrganization', { organizationId: message.senderId }, { root: true })
  await stelace.messages.remove(message.id)
  if (currentUserId && currentUserId.startsWith('org_')) dispatch('auth/selectOrganization', { organizationId: currentUserId }, { root: true })
}

export async function markConversationAsRead ({ dispatch, rootState }, { messages }) {
  if (messages.length) {
    const currentUserId = rootState.auth.user.id
    for (const msg of messages) {
      if (msg.receiverId && msg.receiverId.startsWith('org_')) dispatch('auth/selectOrganization', { organizationId: msg.receiverId }, { root: true })
      await stelace.messages.update(msg.id, { read: true })
      if (currentUserId && currentUserId.startsWith('org_')) dispatch('auth/selectOrganization', { organizationId: currentUserId }, { root: true })
    }
  }
}

// Templates related
export async function getTemplates (context, { authorId, label }) {
  const res = await stelace.forward.get(`/templates?authorId=${authorId}${label ? `&label=${label}` : ''}`)
  return res.results
}

export async function createTemplate (context, template) {
  const res = await stelace.forward.post('/templates', template)
  return res.results
}

export async function editTemplate (context, { templateId, template }) {
  return await stelace.forward.patch(`/templates/${templateId}`, template)
}

export async function deleteTemplate (context, templateId) {
  return await stelace.forward.del(`/templates/${templateId}`)
}

export async function sendMailTemplate (context, config) {
  if (!config.name) config.name = 'genericMail'
  if (!config.locale) config.locale = 'fr'
  if (!config.data.buttonLink) config.data.buttonLink = process.env.HC_APP_URL
  if (!config.data.buttonName) config.data.buttonName = 'Consulter 💬'
  return await stelace.forward.post('/emails/send-template', config)
}
