<template>
  <div ref="GridMediaManager" class="fit q-pa-md">
    <div>
      <template v-if="filteredMedias.length">
        <!-- v-show instead of v-if allow better rendering time when switching draggableActive -->
        <div v-show="!draggableActive" class="row q-col-gutter-md">
          <component
            :is="componentTypes[item.type]"
            v-for="(item, index) in filteredMedias"
            :key="index"
            :item="item"
            class="col-12 col-sm-6 col-md-4 col-lg-3"
            @open="open(item)"
          />
        </div>
        <draggable v-show="draggableActive" v-model="filteredMedias" item-key="key" class="row q-col-gutter-md" @change="updateEntity">
          <!-- eslint-disable-next-line vue/no-unused-vars -->
          <template #item="{element}">
            <component
              :is="componentTypes[element.type]"
              :item="element"
              class="col-12 col-sm-6 col-md-4 col-lg-3 cur"
              style="cursor: grab !important"
            />
          </template>
        </draggable>
      </template>
      <AppContent v-else path="component.tools.grid_media.no_results" />
    </div>
    <MediaImageDialog
      v-if="mediaImageDialog"
      :readonly="readonly"
      :field="computedField"
      @close="mediaImageDialog = !mediaImageDialog"
      @updateEntity="updateEntity"
    />
    <MediaVideoDialog
      v-if="mediaVideoDialog"
      :readonly="readonly"
      :field="computedField"
      @close="mediaVideoDialog = !mediaVideoDialog"
      @updateEntity="updateEntity"
    />
    <MediaAlbumDialog
      v-if="mediaAlbumDialog"
      :readonly="readonly"
      :field="computedField"
      @close="mediaAlbumDialog = !mediaAlbumDialog"
      @updateEntity="updateEntity"
    />
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import MediaAlbumCard from 'hc-core/components/cards/media-album-card'
import MediaVideoCard from 'hc-core/components/cards/media-video-card'
import MediaPictureCard from 'hc-core/components/cards/media-picture-card'
import MediaImageDialog from 'hc-core/components/dialogs/media-image-dialog'
import MediaVideoDialog from 'hc-core/components/dialogs/media-video-dialog'

export default {
  components: { draggable, MediaAlbumCard, MediaVideoCard, MediaPictureCard, MediaImageDialog, MediaVideoDialog },
  props: {
    type: {
      type: String,
      default: 'gallery'
    },
    medias: {
      type: Array,
      default: () => []
    },
    gridMethod: {
      type: String,
      default: null
    },
    propField: {
      type: Object,
      default: null
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  emits: ['resetGridMethod'],
  data () {
    return {
      computedField: null,
      draggableActive: false,
      mediaAlbumDialog: false,
      mediaImageDialog: false,
      mediaVideoDialog: false,
      componentTypes: {
        album: 'MediaAlbumCard',
        video: 'MediaVideoCard',
        image: 'MediaPictureCard',
      }
    }
  },
  computed: {
    filteredMedias () {
      return this.medias ? this.$_.compact(this.medias.filter((m) => this.type === 'gallery' || (m && m.type === this.type))) : []
    }
  },
  watch: {
    gridMethod: {
      handler: async function (val, oldVal) {
        switch (val) {
          case 'addPicture':
            this.computedField = this.field({ type: 'image' })
            this.mediaImageDialog = true
            break
          case 'addVideo':
            this.computedField = this.field({ type: 'video' })
            this.mediaVideoDialog = true
            break
          case 'addAlbum':
            this.computedField = this.field({ type: 'album', name: '', key: require('shortid').generate(), files: [] })
            this.mediaAlbumDialog = true
            break
          case 'changeOrder':
            this.draggableActive = !this.draggableActive
            break
        }
        this.$emit('resetGridMethod')
      }
    },
  },
  methods: {
    field (value) {
      if (this.propField) {
        const propFieldCopy = Object.assign({}, this.propField)
        if ((value.key && value.type !== 'album') || value.url) {
          propFieldCopy.key = `${this.$_.get(propFieldCopy, 'key', '')}.files[${this.$_.get(propFieldCopy, 'value.files', []).indexOf(value)}]`
        } else propFieldCopy.key += `.files[${this.$_.get(propFieldCopy, 'value.files', []).length}]`
        propFieldCopy.isNew = ['addPicture', 'addVideo', 'addAlbum'].includes(this.gridMethod)
        propFieldCopy.value = this.$_.get(this.userCompany, propFieldCopy.key, { caption: '', type: value.type })
        return propFieldCopy
      }

      const key = `metadata._files.albums[${value && Object.keys(value).length > 1
        ? this.$_.get(this.userCompany, 'metadata._files.albums', []).indexOf(value) >= 0
          ? this.$_.get(this.userCompany, 'metadata._files.albums', []).indexOf(value)
          : this.$_.get(this.userCompany, 'metadata._files.albums', []).length
        : this.$_.get(this.userCompany, 'metadata._files.albums', []).length}]`

      let uploadFolder = `assets/albums/${this.$_.get(this.userCompany, 'metadata._files.key', require('shortid').generate())}`
      if (this.$_.get(value, 'key', false) && value.type === 'image') {
        uploadFolder = value.key.split('/')
        uploadFolder.pop()
        uploadFolder = uploadFolder.join('/')
      }
      if (this.$_.get(value, 'key', false) && value.type === 'album') {
        uploadFolder = `${uploadFolder}/${value.key}`
      }
      return {
        key,
        value,
        // type: 'img',
        uploadFolder,
        isNew: ['addPicture', 'addVideo', 'addAlbum'].includes(this.gridMethod),
        prefix: 'timestamp',
        icon: 'uil:image-plus', // à adapter
        aspectRatio: Number.NaN, // Allow free aspectRatio
        size: { width: '100%', height: '100%' },
        hint: 'Cliquez ou glissez une image ici', // à adapter
        label: 'Ajouter une image', // à adapter
      }
    },
    open (item) {
      this.computedField = this.field(item)
      switch (item.type) {
        case 'image': this.mediaImageDialog = true; break
        case 'video': this.mediaVideoDialog = true; break
        case 'album': this.mediaAlbumDialog = true; break
      }
    },
    async updateEntity (obj) {
      try {
        const entityCopy = Object.assign({}, obj.entity ?? this.userCompany)
        if (!this.$_.get(obj, 'moved', false)) { // Update from components
          if (obj.method === 'edit') {
            // TODO: remove old image from s3
            // this.$_.get(entityCopy, obj.field.key, obj.value)
            this.$_.set(entityCopy, obj.field.key, obj.value)
          }

          if (obj.method === 'delete') {
            // TODO: remove old image from s3
            // this.$_.get(entityCopy, obj.field.key, obj.value)
            this.$_.set(entityCopy, obj.field.key, null)
          }
        }
        if (this.$_.get(obj, 'moved', false)) { // Drag event
          const arr = this.$_.get(entityCopy, 'metadata._files.albums', [])
          if (obj.moved.newIndex >= arr.length) {
            let k = obj.moved.newIndex - arr.length + 1
            while (k--) { arr.push(undefined) }
          }
          arr.splice(obj.moved.newIndex, 0, arr.splice(obj.moved.oldIndex, 1)[0])
          this.$_.set(entityCopy, 'metadata._files.albums', arr)
          // Files are moved inside an album - erase precedent code
          if (this.$_.get(this.propField, 'value.type', false) === 'album') {
            const albumFiles = this.propField.value.files
            albumFiles.splice(obj.moved.newIndex, 0, albumFiles.splice(obj.moved.oldIndex, 1)[0])
            this.$_.set(entityCopy, `${this.propField.key}.files`, albumFiles)
          }
        }

        await this.$store.dispatch('asset/updateCompany', {
          asset: entityCopy,
          attrs: {
            metadata: {
              _files: {
                albums: this.$_.get(entityCopy, 'metadata._files.albums', []).filter((a) => a && ((a.type === 'video' && a.url) || (a.type === 'image' && a.key) || (a.type === 'album'))) // Prevent draggable from inserting undefined or null values
              },
            },
          }
        })
        this.notifySuccess('notification.saved')
      } catch (e) { this.useLogger(e) }
    }
  },
}
</script>
