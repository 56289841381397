import { get } from 'lodash'

export function inbox (state) {
  return state.inbox
}

export function talkAssets (state) {
  return {
    userTopicId: get(state.talkAssets.find((a) => a.name === 'user_talk'), 'id', null),
    choTopicId: get(state.talkAssets.find((a) => a.name === 'cho_talk'), 'id', null),
    administrativeTopicId: get(state.talkAssets.find((a) => a.name === 'administrative_talk'), 'id', null),
  }
}
