<template>
  <QSelect
    v-model="cptValue"

    :options="computedOptions"
    :map-options="mapOptions"
    :emit-value="emitValue"
    :option-value="optionValue"
    :option-label="optionLabel"

    :disable="disable"
    :readonly="readonly"
    :label="computedLabel(label)"
    :display-value="computedLabel(displayValue)"
    :bottom-slots="bottomSlots"

    outlined
    dense
    class="br-8"
    :bg-color="bgColor"
    :label-color="labelColor"
  >
    <template v-for="(_, slot) of $slots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope || {}" />
    </template>
  </QSelect>
</template>

<script>
import { arrayIfFilter } from 'hc-core/composables/misc.js'

export default {
  props: {
    // Model and options
    modelValue: {
      required: true
    },

    // Options
    options: {
      type: Array,
      default: () => []
    },
    mapOptions: {
      type: Boolean,
      default: true
    },
    emitValue: {
      type: Boolean,
      default: true
    },
    optionValue: {
      type: String,
      default: undefined
    },
    optionLabel: {
      type: String,
      default: undefined
    },

    // State
    disable: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: null
    },
    displayValue: {
      type: String,
      default: undefined
    },
    bottomSlots: {
      type: Boolean,
      default: false
    },

    // Styling
    bgColor: {
      type: String,
      default: undefined
    },
    labelColor: {
      type: String,
      default: undefined
    },
  },
  emits: ['update:modelValue'],
  computed: {
    cptValue: {
      get () { return this.modelValue },
      set (value) { this.$emit('update:modelValue', value) }
    },
    computedOptions () {
      return arrayIfFilter(this.$_.compact(this.options.map((option) => {
        const supposedLabel = option[this.optionLabel ?? 'label']
        if (supposedLabel) option[this.optionLabel ?? 'label'] = this.computedLabel(supposedLabel)
        return option
      })))
    }
  },
  methods: {
    computedLabel (label) {
      return label ? this.translationExists(label) ? this.$t({ id: label }) : label : false
    },
  }
}
</script>
