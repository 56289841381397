<template>
  <QCard v-if="item.url" ref="MediaVideoCard" flat class="cursor-pointer br-25 overflow-hidden" @click="$emit('open', item)">
    <LazyYtVideo :src="embedURL(item.url)" :ratio="16 / 9" style="pointer-events: none; height:70%!important" />
    <QSeparator v-if="item.caption" />
    <QCardSection v-if="item.caption" class="bg-grey-1">
      <div class="text-body1 text-semibold items-center full-width">
        {{ item.caption }}
      </div>
    </QCardSection>
  </QCard>
</template>

<script>
import { embedURL } from 'hc-core/composables/misc.js'
import LazyYtVideo from 'hc-core/components/tools/lazy-yt-video'

export default {
  components: { LazyYtVideo },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  emits: ['open'],
  methods: {
    embedURL
  },
}
</script>
