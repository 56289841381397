<template>
  <QCard v-if="item" ref="MediaAlbumCard" flat class="cursor-pointer br-25" @click="$emit('open', item)">
    <div class="q-pa-md">
      <div v-if="$_.get(item, 'files', []).length" class="row q-col-gutter-sm">
        <div v-for="(file, i) of $_.clone(item.files).splice(0, 8)" :key="i" class="col-3">
          <QImg v-if="file && (file.type === 'image' || file.type === undefined)" :src="cdnImg(file.key)" :ratio="1" class="br-4" loading="lazy" />
          <LazyYtVideo v-if="file && file.type === 'video'" :src="embedURL(file.url)" class="br-4" :ratio="1" style="pointer-events: none" />
        </div>
      </div>
      <AppContent v-else path="component.cards.media_album_card.nothing" />
    </div>
    <QSeparator v-if="item.name" />
    <QCardSection v-if="item.name" class="bg-grey-1">
      <div class="text-body1 text-semibold items-center full-width">
        {{ item.name }}
      </div>
    </QCardSection>
  </QCard>
</template>

<script>
import { embedURL } from 'hc-core/composables/misc.js'
import LazyYtVideo from 'hc-core/components/tools/lazy-yt-video'

export default {
  components: { LazyYtVideo },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  emits: ['open'],
  methods: {
    embedURL
  },
}
</script>
