<template>
  <QCard v-if="item" ref="MediaPictureCard" flat class="cursor-pointer br-25" @click="$emit('open', item)">
    <img :src="cdnImg(item.key, { width: 500 })" loading="lazy">
    <QSeparator v-if="item.caption" />
    <QCardSection v-if="item.caption" class="bg-grey-1">
      <div class="text-body1 text-semibold items-center full-width">
        {{ item.caption }}
      </div>
    </QCardSection>
  </QCard>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  emits: ['open'],
}
</script>
